export default [
    {
        header: 'Volunteers',
        resource: 'volunteers',
        action: 'read',
    },
    {
        title: 'Lists',
        route: 'volunteers',
        icon: 'ListIcon',
        resource: 'volunteers',
        action: 'read',
    },
    {
        title: 'Check-Ins',
        route: 'absences',
        icon: 'CheckIcon',
        resource: 'absences',
        action: 'read',
    },
]