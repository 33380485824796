export default [
    {
        header: 'Users & Roles',
        resource: 'user',
        action: 'read',
    },
    {
        title: 'Users',
        route: 'users',
        icon: 'UsersIcon',
        resource: 'user',
        action: 'read',
    },
    {
        title: 'Roles',
        route: 'roles',
        icon: 'LockIcon',
        resource: 'role',
        action: 'read',
    },
]