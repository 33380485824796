export default [
  {
    header: 'Servolution',
    resource: 'servolution_events',
    action: 'read',
  },
  {
    title: 'Ministries',
    route: 'servolution-ministries',
    icon: 'ListIcon',
    resource: 'servolution_ministries',
    action: 'read',
  },
  {
    title: 'Divisions',
    route: 'servolution-divisions',
    icon: 'ListIcon',
    resource: 'servolution_divisions',
    action: 'read',
  },
  {
    title: 'Events',
    route: 'servolution-events',
    icon: 'CalendarIcon',
    resource: 'servolution_events',
    action: 'read',
  },
  {
    title: 'Baptism',
    route: 'servolution-baptism',
    icon: 'UserCheckIcon',
    resource: 'servolution_baptism',
    action: 'read',
  },
]