<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData[0].name }}
        </p>
        <span class="user-status">{{ userData[0].role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData[0].photo"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData[0].name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
// import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import { avatarText } from '@core/utils/filter'
// import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
// import jwt from '@/auth/jwt/jwt'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8)),
      // userData: JSON.parse(Cookies.get('userData')),
      // user: JSON.parse(Cookies.get('userName')),
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      sessionStorage.clear()
      // Cookies.remove(jwt.jwtConfig.storageTokenKeyName)
      // Cookies.remove('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.replace('/login')
      // window.location.href = '/login'
    },
  },
}
</script>
